import background from './images/background-studio.png';
import './App.css';
import CountDown from './components/CountDown';

const App = () =>  {
  return (
    <div className="App">
      <header className="App-header" style={{backgroundImage: `url(${background})`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundAttachment:"fixed", backgroundSize: "cover"}}>

        {/* TODO: keep this line right here */}
        {/* <img src={background} className="App-logo" alt="logo" /> */}


        <h1>Dropping In:</h1>
        <CountDown/>
      </header>
    </div>
  );
}

export default App;
